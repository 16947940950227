
import axios from "axios";
import * as moment from "moment-timezone";
import HomeBanner from "~/components/HomeBanner";
import Footer from "~/components/Footer";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  layout: "layoutm1",
  middleware: "redirect",
  components: {
    VueSlickCarousel,
    HomeBanner,
    Footer,
  },
  async asyncData({
    params,
    env,
    query,
    $axios,
    req,
    store,
    $t,
    app: { i18n },
  }) {
    return {
      utm_source: query.utm_source,
      utm_medium: query.utm_medium,
      utm_campaign: query.utm_campaign,
      utm_id: query.utm_id,
      utm_term: query.utm_term,
      utm_content: query.utm_content,
    };
  },
  data() {
    return {
      value: 4.567,
      email: "",
      visible_offer: true,
      visible_terms: false,
      showFooter: false,
      couponList: null,
      terms: false,
      couponCount: [],
      couponDetails: null,
      settings: {
        dots: true,
        arrows: false,
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      silde_settings: {
        dots: false,
        arrows: true,
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 980,
            settings: {
              arrows: true,
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      currentPage: 1,
      rows: 0,
      perPage: 12,
      showPage: false,
      showIEError: false,
      lang: "en-US",
      paymentDetails: null,
      countryList: [],
      country_list: [],
      campaigns: null,
      show: false,
      opacity: 0.5,
    };
  },
  head() {
    return {
      title: `Hyatt`,
      meta: [
        {
          hid: "viewport",
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=no",
        },
        {
          hid: "description",
          name: "description",
          content: "Hyatt Official Online Voucher Store.",
        },
        {
          property: "og:url",
          // content:`${this.merchantDetails.coupon_url}`,
          vmid: "og:url",
        },
        {
          property: "og:description",
          content: "Hyatt Official Online Voucher Store.",
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: "/icon.png",
          vmid: "og:image",
        },
      ],
      link: [
        { rel: "icon", type: "image/x-icon", href: "/icon.png" },
        { rel: "stylesheet", href: "/css/style.css" },
        { rel: "stylesheet", href: "/css/main.css" },
        { rel: "stylesheet", href: "/css/font-awesome.min.css" },
      ],
      script: [
        { src: "/js/jquery.min.js", body: true },
        {
          src: "https://www.hyatt.com/hds/bookends/1.latest/partner/en-US/bookends.min.js",
          body: true,
          "data-bookends": "script",
        },
        {
          src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
          charset: "UTF-8",
          "data-domain-script": "453c50ad-1176-479f-9ffd-82c9deb09e1c",
        },
        { src: "https://www.google-analytics.com/analytics.js", async: true },
        {
          innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
              m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
              })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
              ga('create', 'UA-96639441-1', 'auto')
              ga('send', 'pageview')
              `,
          type: "text/javascript",
          charset: "utf-8",
        },
        {
          src: "https://www.googletagmanager.com/gtag/js?id=AW-977747240",
          async: true,
        },
        {
          src: "https://www.googletagmanager.com/gtag/js?id=G-MCJR40CPQE",
          async: true,
        },
        {
          innerHTML: `function OptanonWrapper() { };
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-977747240');
          gtag('config', 'G-MCJR40CPQE');
              `,
          type: "text/javascript",
          charset: "utf-8",
        },
        {
          innerHTML: `
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
          n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
          document,'script','https://connect.facebook.net/en_US/fbevents.js');
  
              fbq('init', '623497044413616'); 
              fbq('track', 'PageView');
         
              `,
          type: "text/javascript",
          charset: "utf-8",
        },
      ],
      noscript: [
        {
          innerHTML: `<img height="1" width="1" src="https://www.facebook.com/tr?id=623497044413616&ev=PageView&noscript=1"/>`,
        },
      ],
      __dangerouslyDisableSanitizers: ["script", "noscript", "innerHTML"],
    };
  },
  methods: {
    gethtml(value) {
      var strippedHtml = value
        .replace(/<[^>]+>/g, "<p>")
        .replace(/&nbsp;/g, "")
        .replace(/(\r\n|\n|\r)/gm, "");
      console.log(strippedHtml, "strippedHtml");
      return strippedHtml;
    },
    gotoPage: function (country_code, country_name) {
      console.log("country_code", country_code, country_name);
      if (country_name !== "Hong Kong" && country_name !== "Thailand") {
        if (country_code.length > 1) {

          if (this.utm_source && this.utm_medium && this.utm_campaign && this.utm_id && this.utm_term && this.utm_content) {
            var url =`/country?country_name=${country_name}&utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_campaign=${this.utm_campaign}&utm_id=${this.utm_id}&utm_term=${this.utm_term}&utm_content=${this.utm_content}`;
          }else{
            var url = `/country?country_name=${country_name}`;                 
          }
          
          window.open(url);
        } else {
          if (this.utm_source && this.utm_medium && this.utm_campaign && this.utm_id && this.utm_term && this.utm_content) {

            var url = `/${country_code[0]}?utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_campaign=${this.utm_campaign}&utm_id=${this.utm_id}&utm_term=${this.utm_term}&utm_content=${this.utm_content}`
          }else{
            var url = "/" + country_code[0];                 
          }
          window.open(url);
        }
      } else if (country_name == "Hong Kong") {
        if (country_code.length > 1) {
          if (this.utm_source && this.utm_medium && this.utm_campaign && this.utm_id && this.utm_term && this.utm_content) {
            var url =`/hongkong?country_name=${country_name}&utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_campaign=${this.utm_campaign}&utm_id=${this.utm_id}&utm_term=${this.utm_term}&utm_content=${this.utm_content}`;
          }else{
            var url = `/hongkong?country_name=${country_name}`;                 
          }
          
          window.open(url);
        } else {
          if (this.utm_source && this.utm_medium && this.utm_campaign && this.utm_id && this.utm_term && this.utm_content) {

            var url = `/${country_code[0]}?utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_campaign=${this.utm_campaign}&utm_id=${this.utm_id}&utm_term=${this.utm_term}&utm_content=${this.utm_content}`
          }else{
            var url = "/" + country_code[0];                 
          }
          window.open(url);
        }
      } else {
        if (country_code.length > 1) {
          if (this.utm_source && this.utm_medium && this.utm_campaign && this.utm_id && this.utm_term && this.utm_content) {
            var url =`/thailand?country_name=${country_name}&utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_campaign=${this.utm_campaign}&utm_id=${this.utm_id}&utm_term=${this.utm_term}&utm_content=${this.utm_content}`;
          }else{
            var url = `/thailand?country_name=${country_name}`;                 
          }
          
          window.open(url);
        } else {
          if (this.utm_source && this.utm_medium && this.utm_campaign && this.utm_id && this.utm_term && this.utm_content) {

            var url = `/${country_code[0]}?utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_campaign=${this.utm_campaign}&utm_id=${this.utm_id}&utm_term=${this.utm_term}&utm_content=${this.utm_content}`
          }else{
            var url = "/" + country_code[0];                 
          }
          window.open(url);
        }
      }
    },

    getMerchant: async function (property) {
      var merchant = "";
      var url = `/api/mzapps?country_code=${property}&timestamp=${new Date().getTime()}`;
      await this.$axios
        .$get(url)
        .then(async (response) => {
          // console.log(response)

          merchant = response.merchant;
        })
        .catch((error) => {});

      return merchant;
    },
    getCountryList: async function () {
      // alert("call country")
      this.country_list = [];
      await this.$axios
        .$get(`/api/mzapps/mpage?timestamp=${new Date().getTime()}`)
        .then(async (response) => {
          for (var i in response) {
            var find = this.country_list.findIndex(
              (c) => c.country_code === response[i].country
            );
            if (find === -1) {
              this.country_list.push({
                country_code: response[i].country,
                country_name: response[i].country_name,
                country_img: response[i].country_img_url,
                properties: [response[i].property],

                show: true,
              });
            } else {
              this.country_list[find].properties.push(response[i].property);
            }
          }

          var length = this.country_list.length;
          for (var i in this.country_list) {
            var couponLength = 0;

            var merchants = [];
            length = length - 1;
            for (var j in this.country_list[i].properties) {
              var property = this.country_list[i].properties[j];
              var merchant = await this.getMerchant(property);
              // var payment = await this.getActivePayment(property);
              // var campaigns = await this.getCampaigns(property);
              // var count = 0;
              // couponLength += campaigns.summary ? campaigns.summary.totalsize : 0;
              // console.log('campaigns', campaigns);

              // console.log('this.campaigns', campaigns)
              // var filter_campaign = null
              // if (campaigns) {
              //   var campaigns = campaigns.results;
              //     var filter_campaign = campaigns.filter(function (el) {
              //     var date = el.campaign.to_date;
              //     // var today = new Date();
              //     //   var dt = new Date(date);
              //     const t = new Date();
              //     const dates = ('0' + t.getDate()).slice(-2);
              //     const month = ('0' + (t.getMonth() + 1)).slice(-2);
              //     const year = t.getFullYear();
              //     var value = `${year}-${month}-${dates}`;
              //     var today = new Date(value);
              //     var toDate = new Date(date);
              //     if (el.campaign.campaign_validity_period === 'Y') {
              //       return toDate.getTime() >= today.getTime();
              //     } else {
              //       return el.campaign.campaign_validity_period !== 'Y'
              //     }

              //   }
              //   );
              //   }
              //   count += filter_campaign ? filter_campaign.length : 0;
              // console.log('filter_campaign', filter_campaign);

              // console.log('count', count);

              //   this.country_list[i]['vouchers'] = couponLength
              // this.country_list[i]['campaign_length'] = count
              // var list = campaigns.results;
              merchants.push({
                property: property,
                merchant: merchant,
              });
            }
            this.country_list[i]["campaigns"] = merchants;
            // if (this.country_list[i].campaign_length > 0) {
            //   this.country_list[i].show = true;
            // } else {
            //   this.country_list[i].show = false;
            // }
            // if (length === 0) {
            //   this.show = false;
            // }
          }
          console.log("Country list ", this.country_list);
        })
        .catch((error) => {
          console.log("Error ", error);
        });
    },
  },
  mounted: function () {
    this.show = true;
    this.campaigns = [];
    this.getCountryList();
    this.$router.push({
      path: this.$route.path,
      query: {
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        utm_id: this.utm_id,
        utm_term: this.utm_term,
        utm_content: this.utm_content,
      },
    });
    // &utm_source=marketingfirm1&utm_medium=email&utm_campaign=hyatt_june23&utm_id=88888888&utm_term=Test&utm_content=Test
    // this.$router.push({ path: this.$route.path, query: { utm_source: 'marketingfirm1',utm_medium:'email',utm_campaign:'hyatt_june23',utm_id:'88888888',utm_term:'Test',utm_content:'Test' } });
  },
};
